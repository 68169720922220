import store from "@/state/store";

export default [
    // 授權
    // 登入
    {
        path: "/login",
        name: "login",
        component: () => import("@/modules/auth/view/login"),
        meta: {
            title: "Login",
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                // console.log("是否登入過了:", store.getters["auth/loggedIn"])
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    const userInfo = JSON.parse(store.getters["auth/userInfo"]);
                    // console.log("userInfo", userInfo)
                    if (userInfo.role === "admin") {
                        next({ name: "default" });
                    } else {
                        next({ name: "investigate" });
                    }
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    // 登出
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout",
            authRequired: false,
            // beforeResolve(routeTo, routeFrom, next) {
            //   localStorage.clear();
            //   sessionStorage.clear();
            //   next();
            // },
        },
        component: () => import("@/modules/auth/view/logout")
    },
    // 忘記mima
    {
        path: "/forgot-mima",
        name: "forgot-mima",
        meta: {title: "忘記密碼", authRequired: false},
        component: () => import("@/modules/auth/view/forgot-mima"),
    },
    // 註冊
    // {
    //     path: "/register",
    //     name: "Register",
    //     component: () => import("@/modules/auth/view/register.vue"),
    //     meta: {
    //         title: "Register",
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters["auth/loggedIn"]) {
    //                 // Redirect to the home page instead
    //                 next({name: "default"});
    //             } else {
    //                 // Continue to the login page
    //                 next();
    //             }
    //         },
    //     },
    // },


    // 鑑別頁面
    {
        path: "/investigate",
        name: "investigate",
        meta: {
            title: "鑑別",
            authRequired: true,
            // 提示使用者離開填表頁面前，要儲存表單資料
            confirm: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                // console.log("確認身分:", JSON.parse(store.getters["auth/userInfo"]))
                const role = JSON.parse(store.getters["auth/userInfo"]).role;
                if (role !== 'test') {
                    // Redirect to 404 page
                    next({ name: "404" });
                } else {
                    // Continue
                    next();
                }
            },
        },
        component: () => import("@/modules/investigate/views/investigate"),
    },
    // user帳戶管理
    {
        path: "/user-account-management",
        name: "user-account-management",
        component: () => import("@/modules/management/account/views/user-account-management"),
        meta: { title: "帳戶管理", authRequired: true,},
        
    },
    // user修改mima
    {
        path: "/user-change-mima",
        name: "user-change-mima",
        meta: {title: "修改密碼", authRequired: true,},
        component: () => import("@/modules/management/mima/views/user-change-mima"),
    },

    // 管理頁面
    {
        path: "/",
        name: "default",
        meta: { title: "首頁", authRequired: true },
        component: () => import("@/modules/management/default/views/index"),
    },
    // 指標鑑別系統
    {
        path: "/factor-management",
        name: "factor-management",
        meta: { title: "指標鑑別系統", authRequired: true },
        component: () => import("@/modules/management/result/views/factor-management"),
    },
    // 情境分析系統
    {
        path: "/situation",
        name: "situation",
        meta: { title: "情境分析系統", authRequired: true },
        component: () => import("@/modules/management/situation/views/situation.vue"),
    },
    // 管理分析系統
    {
        path: "/management-analysis",
        name: "management-analysis",
        meta: { title: "管理分析系統", authRequired: true },
        component: () => import("@/modules/management/analysis/views/management-analysis"),

    },
    // 產生報告
    {
        path: "/report",
        name: "report",
        meta: { title: "產生報告", authRequired: true },
        component: () => import("@/modules/management/report/views/report"),

    },
    // 設定
    {
        path: "/setting/calculate-value",
        name: "setting-calculate-value",
        meta: { title: "計算數值", authRequired: true },
        component: () => import("@/modules/management/setting/views/calculate-value"),
    },
    {
        path: "/setting/project-management",
        name: "project-management",
        meta: { title: "年度鑑別表單管理", authRequired: true },
        component: () => import("@/modules/management/setting/views/project-management"),
    },
    {
        path: "/setting/asset-location",
        name: "asset-location",
        meta: { title: "資產位置資料設定", authRequired: true },
        component: () => import("@/modules/management/setting/views/asset-location"),
    },
    {
        path: "/setting/factor-option-management",
        name: "setting-factor-option-management",
        meta: { title: "指標選項設定", authRequired: true },
        component: () => import("@/modules/management/setting/views/factor-option-management"),
    },
    {
        path: "/setting/company-info",
        name: "company-info",
        meta: { title: "企業基本資料", authRequired: true },
        component: () => import("@/modules/management/setting/views/company-info"),
    },
    // 匯出報告
    {
        path: "/export",
        name: "export",
        meta: { title: "匯出報告", authRequired: true },
        component: () => import("@/modules/management/report/views/export"),
    },
    // 帳戶管理
    {
        path: "/account-management",
        name: "account-management",
        meta: {title: "帳戶管理", authRequired: true},
        component: () => import("@/modules/management/account/views/account-management"),
    },
    // 修改mima
    {
        path: "/change-mima",
        name: "change-mima",
        meta: {title: "修改密碼", authRequired: true,},
        component: () => import("@/modules/management/mima/views/change-mima"),
    },
    // 錯誤頁面
    {
        path: '/404',
        name: '404',
        meta: {
            title: "Error 404",
            authRequired: false,
        },
        component: () => import('@/modules/auth/view/errors/404-cover')
    },
    {
        path: '/500',
        name: '500',
        meta: {
            title: "Error 500",
            authRequired: false,
        },
        component: () => import('@/modules/auth/view/errors/500')
    },
    {
        path: '/auth/ofline',
        name: 'ofline',
        meta: {
            title: "Offline",
            authRequired: false,
        },
        component: () => import('@/modules/auth/view/errors/ofline')
    },

    // // 測試
    // {
    //     path: "/test",
    //     name: "test",
    //     meta: {
    //         title: "範例模板",
    //         authRequired: false
    //     },
    //     component: () => import("@/modules/test/view/test-view"),
    // },
    // 錯誤路徑
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
        meta: { hidden: true },
    },
];