import {userService} from '@/modules/auth/service/user.service'

// import CryptoJS from "crypto-js";

const CryptoJS = require("crypto-js");
// 使用者登入資訊
export const state = {
    currentToken: localStorage.getItem('cjt'),
    userInformation: localStorage.getItem('userInfo'),
    sessionExpired: localStorage.getItem('se')
}

// 設定使用者登入資訊
export const mutations = {
    SET_CURRENT_TOKEN(state, newValue) {
        state.currentToken = saveState('cjt', newValue);
    },
    SET_USER_INFO(state, newValue) {
        state.userInformation = saveState('userInfo', newValue);
    },
    SET_SESSION_EXPIRED(state, newValue) {
        state.sessionExpired = saveState('se', newValue);
    }
}

// 獲取使用者登入狀態(true/false)
export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentToken
    },

    jwtToken(state) {
        const sk = process.env.VUE_APP_SK;
        const iv = process.env.VUE_APP_SK;
        const decrypted = CryptoJS.AES.decrypt(state.currentToken, sk, {iv: CryptoJS.enc.Utf8.parse(iv)});
        // console.log("Decrypted:", token);
        return decrypted.toString(CryptoJS.enc.Utf8);
    },

    userInfo(state) {
        const sk = process.env.VUE_APP_SK;
        const iv = process.env.VUE_APP_SK;
        const decrypted = CryptoJS.AES.decrypt(state.userInformation, sk, {iv: CryptoJS.enc.Utf8.parse(iv)});
        const userInfo = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        sessionStorage.setItem("account", userInfo.account);
        //用account取得accountId，存在sessionStorage
        userService.getAccountByAccount({account: userInfo.account}).then(res => {
            const accountId = res.dataSet[0].uuid;
            const companyId = res.dataSet[0].companyId;
            const roleId = res.dataSet[0].roleId;
            const activedStatus = res.dataSet[0].activedStatus;
            sessionStorage.setItem("accountId", accountId);
            sessionStorage.setItem("companyId", companyId);
            sessionStorage.setItem("roleId", roleId);
            sessionStorage.setItem("activedStatus", activedStatus);
        })
        return decrypted.toString(CryptoJS.enc.Utf8);
    },

    isSessionExpired(state) {
        const sk = process.env.VUE_APP_SK;
        const iv = process.env.VUE_APP_SK;
        // console.log("userInfo - state :", state.userInformation);
        const decrypted = CryptoJS.AES.decrypt(state.sessionExpired, sk, {iv: CryptoJS.enc.Utf8.parse(iv)});

        // console.log("Decrypted:", sessionExpired);
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}

// 調用方法
export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    // init({state, dispatch}) {
    //     dispatch('validate')
    // },

    // Logs in the current user.
    logIn({commit}, req) {
        return userService.getJWTToken(req).then(
            async res => {
                if (res.data) {
                    // step1 儲存token到localStorage(未來可以看要不要放在cookie)
                    commit('SET_CURRENT_TOKEN', res.data);
                    commit('SET_SESSION_EXPIRED', 0);
                    // console.log("JWT TOKEN:", res.data)
                    // step2 取得使用者資訊
                    const request = {
                        taxId: req.taxId,
                        account: req.username
                    }
                    return await getUserInfo({commit}, request);
                } else {
                    console.log("Keycloak驗證異常");
                    commit('SET_SESSION_EXPIRED', null);
                    return Promise.reject({status: 403, data: "Keycloak驗證異常"});
                }
            },
            error => {
                // commit('loginFailure', error);
                // dispatch('notification/error', error, {root: true});
                console.log("登入失敗!")
                commit('SET_SESSION_EXPIRED', null);
                // return {status: 500, data: error};
                return Promise.reject({status: 500, data: error})
            }
        )
    },

    // Logs out the current user.
    logOut({commit}) {
        commit('SET_CURRENT_TOKEN', null);
        commit('SET_USER_INFO', null);
        commit('SET_SESSION_EXPIRED', null);
        userService.logout();
    },

    // register the user
    // todo 待修改
    register({commit, dispatch, getters}, {username, password, email, taxId} = {}) {
        // if (getters.loggedIn) return dispatch('validate')
        const userInfo = {
            username: username,
            password: password,
            email: email,
            taxId: taxId
        }
        return userService.register(userInfo).then((response) => {
            const user = response
            commit('SET_CURRENT_TOKEN', user, null, null)
            return user
        });
    },


    setSessionExpired({commit}) {
        commit('SET_SESSION_EXPIRED', 1);
    }

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    // validate({commit, state}) {
    //     if (!state.currentToken) return Promise.resolve(null)
    //     // const user = getFirebaseBackend().getAuthenticatedUser();
    //     const user = userService.login();
    //     commit('SET_CURRENT_TOKEN', user)
    //     return user;
    // },
}

// ===
// Private helpers
// ===

const saveState = (key, state) => {
    if(state === null){
        window.localStorage.removeItem(key);
        return null;
    }
    const sk = process.env.VUE_APP_SK;
    const iv = process.env.VUE_APP_SK;
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(state), sk, {iv: CryptoJS.enc.Utf8.parse(iv)});
    const ciphertext = encrypted.toString();
    window.localStorage.setItem(key, ciphertext)
    return ciphertext;
}

const getUserInfo = ({commit}, req) => {
    return userService.getUserInfo(req).then(
        res => {
            const data = res.dataSet
            // console.log("使用者資訊:", data)
            if(data){
                commit('SET_USER_INFO', {
                    account: data.account,
                    username: data.chineseName,
                    role: data.mainIdentity,
                    plan: data.planType,
                    depId: data.departmentId,
                    unitName: data.unitName,
                    depName: data.departmentName,
                    companyId: data.companyId
                });
                return Promise.resolve({status: 200, data: data})
            }else {
                commit('SET_CURRENT_TOKEN', null);
                // console.log("取得使用者資訊失敗，data為空或不存在", res)
                return Promise.reject({status: 403, data: "系統異常，獲取使用者資訊失敗!"});
            }
        },
        error => {
            // console.log("取得使用者資訊失敗")
            return Promise.reject({status: error?.response?.status || 400 , data: "系統異常，獲取使用者資訊失敗!"});
        }
    )
}
